* {
    font-family: "Heebo",sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    line-height: 1.5;
    color: #5f5f5f;
    letter-spacing: 1px;

    box-sizing: border-box;
}

@font-face{
    font-family: 'akkurat';
    src: url('../fonts/FontsFree-Net-Akkurat.ttf')
}

.container {
    width: 100%;
    margin: 0;
    padding: 0;
}

input:focus,
select:focus,
textarea:focus {
    outline: none;    
}

button, a { cursor: pointer }

button:focus {
    outline: thin dotted;
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: 0px;
}

button,
.link-api-collection {
    width: 100%;
    font-size: 14px;
    padding: 12px;
    line-height: 14px;
    border-radius: 15px;
    background: #007bff;
    border-color: #007bff;
    color: #ffffff;
    cursor: pointer;
    font-weight: 700;
    font-size: 0.8rem;
}

img{
    display: block;
    max-width: 100%;
}

h1, h2, h3, h4 {
    width: 100%;
    margin-top: 0;
    font-style: normal;
    font-family: "Akkurat",sans-serif;
}

h1 {
    font-size: 35px;
    margin-bottom: 20px;
    line-height: 20px;
    color: #000;
}

h2, h3 {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
}

h4 {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.text {
    font-family: "Heebo",sans-serif;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
}


