/*-------------*/
    /*Wave*/
/*-------------*/

svg { overflow: hidden }

#canvas, #confetti { position: absolute }

.wave-animation {
    display: block;
    width: 100%;
    height: 8em;
    max-height: 100vh;
    margin: 0;
    bottom: -100px;
}

.wave-bg>use:first-child, .wave-bg>use:nth-child(2) {
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

.wave-bg>use {
    -webkit-animation: move-forever 20s linear infinite;
    animation: move-forever 20s linear infinite;
}

@keyframes move-forever {
    0% {
        transform: translate(-90px);
    }

    100% {
        transform: translate(85px);
    }
}

.wave-bg>use:nth-child(2) {
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
}

.wave-bg>use:first-child, .wave-bg>use:nth-child(2) {
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
}

.wave-bg>use {
    -webkit-animation: move-forever 20s linear infinite;
    animation: move-forever 20s linear infinite;
}

.wave-bg>use:nth-child(3) {
    -webkit-animation-delay: -4s;
    animation-delay: -4s;
    -webkit-animation-duration: 8s;
    animation-duration: 8s;
}

.wave-bg>use {
    -webkit-animation: move-forever 20s linear infinite;
    animation: move-forever 20s linear infinite;
}

/*-----------------------------------------------*/
    /*Background Animation - API and UsedBy */
/*-----------------------------------------------*/

img { transition: all .5s ease-in-out 0s }

img, svg { vertical-align: middle }

.zoom-fade,
.zoom-fade-usedBy {
    animation-name: zoom-fade;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: zoom-fade;
    -webkit-animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
}

.bg-animation {
    position: absolute;
    top: 40rem;
    left: 0;
    z-index: 1;
}

.bg-animation-usedby {
    position: absolute;
    top: 130rem;
    left: 50;
}

@keyframes zoom-fade {
    0% {
        transform: scale(.9);
    }
    
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(.9);
    }

}

/*------------------*/
    /*API Elipse*/
/*------------------*/

.section-title {
    margin-left: 4rem;
    margin-bottom: 0;
    position: relative;
}

.title-effect {
    width: 50px;
    height: 50px;
    top: 0;
    position: absolute;
    left: 0;
    opacity: .2;
    -webkit-animation: rotation 12.8s steps(1) 0s infinite;
    animation: rotation 12.8s steps(1) 0s infinite;
}

.title-effect-2 {
    height: 50px;
    position: relative;
    width: 50px;
    margin-bottom: 15px;
    -webkit-animation: inherit;
    animation: inherit;
    opacity: 1;
}

.title-effect-2 .ellipse {
    width: 100%;
    height: 100%;
    background: #2575fc;
    border-radius: 50%;
    -webkit-animation: ellipse-animation 2.4s cubic-bezier(0,-.26,.32,1.22) 0s infinite;
    animation: ellipse-animation 2.4s cubic-bezier(0,-.26,.32,1.22) 0s infinite;
    transform: rotate(0deg);
}

@keyframes ellipse-animation {
    0% {
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
    }

    12.5% {
        border-top-left-radius: 0;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
        transform: rotate(45deg);
    }

    25% {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
        transform: rotate(90deg);
    }

    37.5% {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 50%;
        transform: rotate(135deg);
    }

    50% {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        transform: rotate(180deg);
    }

    62.5% {
        border-top-left-radius: 50%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        transform: rotate(225deg);
    }

    75% {
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        transform: rotate(270deg);
    }

    87.5% {
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 0;
        transform: rotate(315deg);
    }

    100% {
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
        transform: rotate(1turn);
    }
}

.title-effect-2 i {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    font-size: 20px;
}

.title-effect-2 i {
    transform: translateX(-50%) translateY(-50%);
}

.la { line-height: inherit }

.la {
    font: normal normal normal 16px/1 LineAwesome;
    font-size: inherit;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.la, .la-stack { display: inline-block }

i {transition: all .5s ease-in-out 0s }

.la-cubes:before { content: "\f1af" }

/*----------------*/
    /*Used By*/
/*----------------*/

.section-title-usedby {
    margin-bottom: 50px;
    position: relative;
}

.ml-auto, .mx-auto { margin-left: auto }

.mr-auto, .mx-auto { margin-right: auto }

.col-lg-6, .col-md-10 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.title-effect-usedby {
    width: 50px;
    height: 50px;
    margin-left: 42%;
    top: 0;
    position: absolute;
    left: 0;
    opacity: .2;
    -webkit-animation: rotation 12.8s steps(1) 0s infinite;
    animation: rotation 12.8s steps(1) 0s infinite;
}

.title-effect-usedby .white-bar { background: #fff }

.title-effect-usedby .bar-top {
    width: 100%;
    height: 7px;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: left top;
    transform: scaleX(0);
    -webkit-animation: bar-top 3.2s linear 0s infinite;
    animation: bar-top 3.2s linear 0s infinite;
}

@keyframes bar-top {
    0% {
        transform: scaleX(0);
    }
    12.5% {
        transform: scale(1);
    }
    87.5% {
        transform: scale(1);
    }
    100% {
        transform: scaleX(0);
    }
}

.title-effect-usedby .bar-right {
    width: 7px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    transform-origin: left top;
    transform: scaleY(0);
    -webkit-animation: bar-right 3.2s linear 0s infinite;
    animation: bar-right 3.2s linear 0s infinite;
}

@keyframes bar-right {
    0% {
        transform: scaleY(0);
    }
    12.5% {
        transform: scaleY(0);
    }
    25% {
        transform: scale(1);
    }
    75% {
        transform: scale(1);
    }
    87.5% {
        transform: scaleY(0);
    }
    100% {
        transform: scaleY(0);
    }
}

.title-effect-usedby .bar-bottom {
    width: 100%;
    height: 7px;
    position: absolute;
    right: 0;
    bottom: 0;
    transform-origin: right top;
    transform: scaleX(0);
    -webkit-animation: bar-bottom 3.2s linear 0s infinite;
    animation: bar-bottom 3.2s linear 0s infinite;
}

@keyframes bar-bottom {
    0% {
        transform: scaleX(0);
    }
    25% {
        transform: scaleX(0);
    }
    37.5% {
        transform: scale(1);
    }
    62.5% {
        transform: scale(1);
    }
    75% {
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(0);
    }
}

.title-effect-usedby .bar-left {
    width: 7px;
    height: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    transform-origin: left bottom;
    transform: scaleY(0);
    -webkit-animation: bar-left 3.2s linear 0s infinite;
    animation: bar-left 3.2s linear 0s infinite;
}

@keyframes bar-left {
    0% {
        transform: scaleY(0);
    }
    37.5% {
        transform: scaleY(0);
    }
    50% {
        transform: scale(1);
    }
    62.5% {
        transform: scaleY(0);
    }
    100% {
        transform: scaleY(0);
    }
}

/*----------------------------------*/
    /*Navbar and DropDownMenu*/
/*----------------------------------*/

.navbar button.navbar-link::before,
header button.navbar-link::before {
    box-sizing: border-box;
    position: absolute;
    color: transparent;
    content: ".";
    text-shadow: 0 0 transparent;
    font-size: 2em;
    transition: text-shadow .3s,color .3s;
    transform: translateX(-50%);
    pointer-events: none;
    -webkit-animation: opacitychange 1s ease-in-out infinite;
    animation: opacitychange 1s ease-in-out infinite;
    line-height: 0;
}

/*Navbar*/
.button.navbar-link.active:before, 
.navbar button.navbar-link:focus:before,
header button.navbar-link:focus:before,
.navbar button.navbar-link:hover:before,
header button.navbar-link:hover:before{
    color: #2575fc;
    text-shadow: 10px 0 #1c1d3e, -10px 0 #1c1d3e;
}

/* .navbar a button.navbar-link.navbar-item-one::before {
    top: 45px;
    padding-left: 36px;
}    */

.navbar a button.navbar-link.navbar-item-one::before{
    top: 45px;
    padding-left: 70px;
}   

.navbar a button.navbar-link.navbar-item-two::before,
.navbar a button.navbar-link.navbar-item-three::before {
    top: 45px;
    padding-left: 25px;
}  

.navbar a button.navbar-link.navbar-item-four::before {
    top: 45px;
    padding-left: 50px;
}   

.navbar a button.navbar-link.navbar-item-five::before {
    top: 45px;
    padding-left: 90px;
}   

.navbar a button.navbar-link.navbar-item-six::before {
    top: 45px;
    padding-left: 60px;
}   

header a button.navbar-link.nav-item-header::before {
    top: 45px;
    padding-left: 110px;
}   

/*DropDownMenu*/
.dropdown .dropdown-menu button.navbar-link:hover:before{
    color: #2575fc;
    text-shadow: 10px 0 #1c1d3e, -10px 0 #1c1d3e;
    padding-right: 28px;
}

.dropdown .dropdown-menu button.navbar-link::before { padding-left: 0 }  

.dropdown .dropdown-menu button.navbar-link span:hover{ padding-left: 5px }
