/*-------------------*/
    /*Mobile First*/
/*-------------------*/

/*------------------------*/
    /*Component Header*/
/*------------------------*/
header.header{
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    max-width: 97%;
    margin: 0 auto;
    padding: 15px;
    background: #fff;
    box-shadow: 0 0 15px 0 rgba(72,73,121,.15);
}

header.header::before {
    content: "";
    position: absolute;
    background: #fcfbfd;
    width: 92%;
    height: 30px;
    top: 67px;
    left: 4%;
    bottom: -20px;
    z-index: -9;
    box-shadow: 0 0 15px 0 rgba(72,73,121,.15);
}

header.header::after {
    content: "";
    position: absolute;
    background: #fcfbfd;
    width: 93%;
    height: 30px;
    top: 58px;
    bottom: -20px;
    z-index: -9;
    box-shadow: 0 0 15px 0 rgba(72,73,121,.15);
    padding: 0 1px 0 8px;
    left: 3%;
    right: 0;
}

header.fixed-header {
    position: fixed;
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background: #fff;
    padding: 15px 20px;
    box-shadow: 0 0 15px 0 rgba(72,73,121,.15);
}

.translation button{
    width: 5%;
}

header select:focus {
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
} 

header select {
    border: none;
    background-color: #fff;
    outline:0px;
    border: 1px solid #fff;
    font-size: 0.8rem;
    font-weight: 500;
    color: #1c1d3e;
}

header select option {
    font-weight: 500;
    font-size: 0.8rem;
    color: #1c1d3e;
}

.account{
    display: none;
}

.logo{
    width: 6rem;
}


/*--------------------------*/
    /* Component NavBar */
/*--------------------------*/

.navbar { display: none }

.navbar-link {
    padding: 0 15px 0 15px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    color: #1c1d3e;
}

.navbar-link:hover { color:#2575fc }

.navbar{ display: none }

li a button.navbar-link { text-align: left }

/*-------------------------------*/
   /*Component DropdownMenu*/
/*------------------------------*/

.hamburger-react { margin-left: 0 } 

.dropdown .dropdown-menu {
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    max-height: 400px;
    overflow: auto;
    border: none;
    box-shadow: 7px 5px 30px rgba(72,73,121,.15);
    left: 0;
    padding: 32px;
    position: absolute;
    z-index: 99;
    top: 60px;
    width: 100%;
}

.menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.menu ul {
    list-style: none;
    padding: 2%;
    margin: 0;
    background: #fff;
    box-shadow: 7px 5px 30px rgba(72,73,121,.15);
}

.menu ul li { padding: 1.5% }
      
/*-----------------------------*/
      /*Component HomeTop*/
/*-----------------------------*/

.home-top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 4rem 1.5rem 2.5rem 1.5rem;
    text-align: center;
}

.page-title{
    width: 100%;
    margin: 0 auto;
    font-weight: 700;
    font-size: 1rem;
    text-align: center;
}

.lead {
    font-weight: 400;
    font-size: 16px;
    color:#1c1d3e;
}

.download-container{
    display: flex;
    justify-content: center;
    margin: 1rem 0 0 0;
}

.download-container > div { padding: 0.5rem }

.download-container-992px { display: none }

/*---------------------------*/
    /*Component Wave*/
/*---------------------------*/

.canvas-layout { display: none }

/*---------------------------*/
    /*Component HomeApi*/
/*---------------------------*/

.container-home-api { 
    position: relative;
    width: 100%; 
    height: auto;
    padding: 80px 0;
    background-color: #02060b;
}

.container-home-api .home-api{
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0 15px;
}

.container-home-api .home-api-infomation,
.container-home-api .home-api-img{
    flex: 1;
    z-index: 300;
}

.container-home-api .home-api-infomation h2 {
    margin: 5% 0 10% 10%;
    font-size: 28px;
    line-height: 44px;
    letter-spacing: 0;
    color: #fff;
}

.container-home-api .home-api .home-api-infomation button{
    position: relative;
    display: flex;
    margin: 0 auto;
    justify-content:space-between;
    width: 16rem;
    padding: 10% 15% 10% 5%;
    text-align: left;
    font-size: 15px;
}

.container-home-api .home-api .home-api-infomation button i{
    margin-left: 2rem;
    font-size: 1.3rem;
    color: #fff;
}

.container-home-api .home-api .home-api-img .img {
    width: 255px;
    height: auto;
    margin: 10% auto;
}

.link-api{
    margin: 10% 2.9rem;
    color: #fff;
}

.link-api a {
    text-decoration: none;
    background-color: #2575fc;
    color: #fff;
    cursor: pointer;
}

/*--------------------------*/
    /*Component Carousel*/
/*--------------------------*/

.container-carousel { margin: 1px 0 }


.carousel-box {
    width: 80%;
    display: flex;
    flex-direction: column;
    padding: 60px 30px;
    margin: 0 auto 5% auto;
    padding: 60px 30px;
    box-shadow: 0 0 15px 0 rgba(72,73,121,.15);
    background-color: #fff;
    text-align: center;
    border-radius: 10px;
}

.carousel-box img{
    width: 60%;
    margin: 0 auto;
    padding-bottom: 10%;
}

.carousel-box h5 {
    text-align: center;
    font-size: 18px;
    line-height: 28px;
    font-weight: 700;
    color: #1c1d3e;
}

.react-multi-carousel-dot button {
    display: inline-block;
    width: 10px;
    height: 8px;
    margin: 0 3px;
    background: #02060b;
    border-radius: 2px;
    border: solid #02060b;
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    -moz-transition: all .5s ease-in-out;
    -ms-transition: all .5s ease-in-out;
    cursor: pointer;
}

.react-multi-carousel-dot button:hover {
    width: 22px;
    background: #2575fc;
    border: solid #2575fc;
}


/*-------------------------*/
    /*Component Used By*/
/*-------------------------*/

.used-by { 
    width: 100%; 
    height: 500px;
    margin-top: 2%;
    padding: 50px 0 50px 0;
    background-color: #02060b;
}

.used-by-text {
    padding-top: 1rem;
    text-align: center;
    color: #fff;
} 


.used-by-img {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.used-by-img > div {
    padding: 0.8rem;
}

/*--------------------------*/
    /*Component Sign-Up*/
/*--------------------------*/

.subscribe-box {
    position: relative;
    margin-top: -60px;
    z-index: 400;
}

.subscribe-inner {
    width: 100%;
    margin: 0;
    border-radius: 7px;
    box-shadow: 0 0 75px rgba(0,0,0,.1);
    padding: 10% 8% 0 8%;
    background: #fff;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.subscribe-inner h4{ color: #1c1d3e }

.subscribe-box form {
    display: flex;
    flex-direction: column;
    margin: 5% 0;
}

.subscribe-box form { padding: 1rem 0 0 0 }

.subscribe-box .label{ color: #33475b }

.subscribe-box form input {
    max-width: 100%;
    height: 40px;
    margin: 1% 0;
    border-radius: 15px;
    border: 1px solid #cbd6e2;
    background-clip: padding-box;
    background-color: #f5f8fa;
    line-height: 22px;
    color: #33475b;
}

.subscribe-box form p,
.subscribe-box form span,
.subscribe-box form label{
    font-family: "Heebo",sans-serif;
    width: auto;
    margin-bottom: 4px;
    padding: 0;
    margin: 0;
    line-height: 18px;
    white-space: normal;
    color: #f2545b;
    font-weight: normal;
    font-size: 0.7rem;
}

.subscribe-box form { padding: 1rem 0 }

.subscribe-box form p { display: none }

.subscribe-box form button {
    width: 60%;
    margin: 10% auto;
}

/*--------------------------*/
    /*Component Footer*/
/*--------------------------*/

footer{
    position: relative;
    padding: 20px 20px;
    background-image: url(../assets/footer-background.png);
    background-size: cover;
    background-position: 40%;
    background-repeat: no-repeat;
    font-family: "Heebo",sans-serif;
    z-index: 300;
}

.subscribe-footer {
    display: flex;
    flex-wrap: wrap;
    padding: 1% 10px;
}

.subscribe-footer button{
    width: 80%;
    margin: 20% 10% 20% 0;
    padding: 15px 0;
    transition: all .3s cubic-bezier(.75,0,.125,1) 0s;
    font-size: 1rem;
    border-radius: 10px;
}

.subscribe-footer .text,
.subscribe-footer ul li, 
.subscribe-footer ul li i { color: #1c1d3e }
.subscribe-footer ul { margin-bottom: 30px }
.subscribe-footer ul li { margin-bottom: 15px }
.subscribe-footer ul li i { margin-left: 16px }

.center {
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    align-items: center;
}

/*----------------------------------------------*/
    /*Component Footer*/
/*-----------------------------------------------*/
.choose-language {
    width: auto;
    font-size: 0.2rem;
}

/*----------------------------------------------*/
    /*Views "Quem Somos" "Termos de Uso" "API"*/
/*-----------------------------------------------*/

.container-text { margin: 5rem 3.5rem }

.container-text h1,
.container-text .dot li {
    margin-bottom: 2.5rem;
    font-size: 1.3rem;
    letter-spacing: -0.014em;
    font-style: normal;
}

.container-text ol, ul { list-style-position: inside }

.container-text ol .number,
.container-text li .number {
    list-style-type: decimal;
    padding-top: 22px;
}
.container-text ol .roman {
    list-style-type: lower-roman;
    padding-top: 22px;
}
.container-text ol .letter {
    list-style-type: lower-alpha;    
    padding-top: 22px;
}
.container-text ul {
    list-style-type: disc;
    padding-left: 5%;
}

.container-text .dot li { margin-bottom: 0 }

.container-text .letter li, .number li, .roman li{
    font-size: 18px;
    padding-top: 2px;
    font-weight: normal;
}

.list-api li.dashed-border{
    padding-left: 5px;
}

/*View API LI*/
.list-api li {
    padding-left: 20px;
    color: gray;
}

.list-api p,
.list-api .download  { font-weight: 500 }

.link-api-collection .download {
    color: white;
}

.link-api-collection {
    width: 13rem;
    margin-bottom: 1rem;
    text-align: center;
}

/*--------------------*/
    /*Views API's*/
/*--------------------*/

.api {
    margin: 5rem 1.7rem;
    font-size: 0.8rem;
}

.api p{ margin: 0 }

.api > div{ margin: 2rem 0 }

.api h1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: gray;
}

.api h3,
.api h2 {
    margin-top: 2rem;
    font-size: 1.2rem;
    color: gray;
}

.api ul li a{
    color: #000;
    text-decoration: none;
    list-style: none;
}

.api ul {
    list-style: none;
    padding: 0.3rem 0;
}

.api ul li{
    list-style: none;
    font-weight: 500;
}

.link-view-api { 
    text-decoration: none; 
    font-weight: 700;
}

.api ul li span { font-weight: normal }

.break-text-api,
.code-box-black-api p,
.code-box-black-api span {
    white-space: pre-wrap;      /* CSS3 */   
   white-space: -moz-pre-wrap; /* Firefox */    
   white-space: -pre-wrap;     /* Opera <7 */   
   white-space: -o-pre-wrap;   /* Opera 7 */    
   word-wrap: break-word;      /* IE */
}

.blue { 
    font-weight: bold;
    color: #447FD9;    
}

.red { color:crimson }
.black { color: #000 }

.code-box-black-api {
    padding: 10px 5px;
    width: 90%;
    height: auto;
    background-color: black;
    font-size: 0.7rem;
    color: white;
}

.highlight-api {
    width: 90%;
    margin-top: 2rem;
    padding: 1.5rem 1rem 1.5rem 1rem;
    background-color: #da4542;
}


.highlight-api p {
    color: white;
    font-size: 0.8rem;
}

.link-pg-api{
    color: grey;
    text-decoration: none;
    font-weight: 600;
}

.link-api:hover {
    color: grey;
    font-weight: 600;
}

.no-padding { padding: 0 }
.no-padding-bottom { padding-bottom: 0 }
.no-padding-top { padding-top: 0 }
.no-margin { margin: 0 }
.no-margin-bottom { margin-bottom: 0 }
.no-margin-top { margin-top: 0 }
.padding-left-20px { padding-left: 20px; }
.padding-left-40px { padding-left: 40px; }
.padding-left-60px { padding-left: 60px; }
.padding-left-view { padding: 0 50px}
.padding-left-bottom { padding: 0 0 10px 20px }
.padding-bottom-23px { padding-bottom: 23px }
.padding-bottom-15px { padding-bottom: 15px }
.padding-bottom-5px { padding-bottom: 5px }
.padding-bottom-30px { padding-bottom: 30px }
.padding-top-25px { padding-top: 25px }
.padding-top-5px { padding-top: 5px }
.italic p, 
.italic span { 
    font-style: italic 
}
.strong { font-weight: 500 }
.blue-no-strong { color: #447FD9 }

.size-picture{ 
    max-width:30rem;
    max-height:30rem;
    width: auto;
    height: auto;
}

.view-pix {
    width: auto;
    height: auto;
    border: 1px solid #000;
}

.line-api {
    width: 50%;
    margin: 10px 0;
    border: 1px solid gray;
}


/*-------------------------------*/
    /*Component WithdrawForm */
/*-------------------------------*/

.withdrawform{
    width: 100%;
    padding: 1rem 0;
    margin: 0 auto;
}

.line {
    width: 50%;
    margin-top: 10px;
    border: 1px solid black;
}

.withdrawform .form{ padding: 1rem 0 }

.withdrawform form input{
    display: block;
    margin: 5px 0 0 0;
    border: 1px solid #000;
    padding: 0.3rem 1.5rem;
    border-radius: 10px;
}

.withdrawform form input.account-number{
    width: 8rem;
    margin: 10px 10px 10px 0;
    padding: 0.3rem 1rem;
}

.withdrawform form input.digit-code{
    width: 5.3rem;
    margin: 10px 0 0 0 ;
    padding: 0.3rem 0.1rem;
}

.withdrawform form label {
    font-size: 0.5rem;
}

.withdrawform form label span{
    color: green;
}

.withdrawform form .withdrawform-flex{
    display: flex;
}

/*-------------------------------*/
    /*Component BoxBlackAPI */
/*-------------------------------*/
.code-box-black-api p, .code-box-black-api span { color: white }

/*-------------------------------*/
    /*Component Table */
/*-------------------------------*/
.table-api {
    width: 90%;
    margin: 0;
    padding: 0;
}

.table-api, .table-api th, .table-api tr, .table-api td { 
    color: #000; 
    border: 1px solid black;
    font-size: 0.3rem;
    padding: 1px;
}

.table-api th { background-color: orange }


/*-------------------------------*/
            /* contact */
/*-------------------------------*/

.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15rem;
    margin: 10rem auto;
    padding: 3rem;
    border: 1px solid transparent;
    box-shadow: 0 0 1em #87827F;
}

.contact h1 {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    letter-spacing: -0.014em;
    font-style: normal;
}

.contact form{
    display: flex;
    flex-direction: column;
}

.contact form input,
.contact form select,
.contact form textarea {
    margin: 1rem 0 0 1rem;
    width: 12rem;
    border: 0.01rem solid #C2C2C0;
    background: transparent;
    margin-top: 0;
}

.erro-contact {
    font-family: "Roboto", sans-serif;
    color: red;
}

.error-div {
    height: 30px;
    padding: 0 0 0 1rem;
}

.lodding-contact {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 8rem 0 30rem 0;
}

/*-------------------------------*/
       /* Thanks and Erro*/
/*-------------------------------*/

.thanks,
.erro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10rem auto;
    text-align: center;
}

.thanks i {
    color: #447FD9;
    padding-bottom: 2rem;
}

.erro i {
    color: #9B0004;
    padding-bottom: 2rem;
}

.thanks h1,
.erro h1 {
    font-size: 2rem;
}

.error-div {
    height: 50px;
}

