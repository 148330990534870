/*-------------------*/
    /*Mobile First*/
/*-------------------*/

/*Small devices (landscape phones, 576px and up) */
@media (min-width: 490px) and (max-width: 767px) { 

    /* Component DropdownMenu */
    .hamburger-react { margin-left: 2rem }

    /* Component HomeTop */
    .page-title{
        width: 50%;
        margin: 0 auto;
        line-height: 25px;
        font-size: 1.3rem;
    }

    .home-top-cell{
        display: flex;
        width: auto;
        padding: 0 5rem;
    }

    /* Component Wave */
    .canvas-layout { display: none }

    /* Component Home Api */
    .container-home-api .home-api{
        flex-direction: column;
        height: auto;
    }
    
    .container-home-api .home-api-infomation h2 {
        margin-left: 10%;
        font-size: 40px;
    }
        
    .container-home-api .home-api .home-api-infomation button{
        width: 80%;
        margin-bottom: 5rem;
        padding: 5% 10% 5% 8%;
    }
    
    .link-api{
        margin: 0 15%;
    }

    .container-home-api .home-api .home-api-img .img {
        width: auto;
        margin: 0;
        padding-bottom:6rem;
    }

    /* Component usedBy */
    div.title-effect-usedby {
        margin-left: 44%;
    }

    /* Component Footer */
    .subscribe-footer .text {
        width: 100%;
        margin: 0 2rem 0 0;
    }

    .subscribe-footer button{
        width: 65%;
        margin: 10% 10% 10% 0;
        padding: 18px 5px;
    }

    .subscribe-footer ul {
        margin-left: 1.2rem;
    }

    .subscribe-footer ul li {
        margin-bottom: 20px;
    }

    /*Component ApiCodes*/

    .choose-language {
        font-size: 0.6em;
    }

    /* Component Table */
    .table-api, .table-api th, .table-api tr, .table-api td {
        padding: 1px; 
        font-size: 0.8rem;
    }

    /*Contact*/
    .contact {
        width: 20rem;
        margin: 10rem auto;
        padding: 3rem;
    }

    .contact h1 {
        font-size: 1rem;
    }

    .contact form input,
    .contact form select,
    .contact form textarea {
        /* margin-bottom: 1rem; */
        width: 15rem;
    }

}


/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 

    /* Component DropdownMenu */
    .hamburger-react { margin-left: 25rem }

    /* Component HomeTop */
    .page-title{
        width: 100%;
        margin: 0 auto;
        line-height: 35px;
        font-size: 1.3rem;
    }

    .home-top-cell{
        width: auto;
        padding: 0 15rem;
        padding: 1rem auto;
    }

    /* Component HomeApi */
    .container-home-api .home-api .home-api-img .img {
        width: auto;
        margin: 0;
        padding-bottom:3rem;
    }

    /* Component Used By */
    .used-by-img > div {
        padding: 3rem 0.5rem;
    }

    .col-md-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
    }

    /* Component UsedBy */
    div.title-effect-usedby {
        margin-left: 46%;
    }
    
    /* Component Sign-Up */
    .subscribe-inner {
        width: 98%;
        margin: 0 auto;
    }
    
    .subscribe-inner {
        width: 90%;
        margin: 0 auto;
        padding: 0 5%;
        flex-direction: row;
        justify-content:left;
    }
    
    .subscribe-inner h4{
        font-size: 1.5rem;
    }
        
    .subscribe-box form {
        margin: 50px 0 0 0;
    }
        
    .subscribe-box form input {
        padding: 0 8%;
    }
    
    .subscribe-inner-text{
        align-self: flex-end;
        padding-bottom: 7%;
    }

    .subscribe-inner-text .text{ width: 90% }

    /* Component Wave */
    .canvas-layout { display: none }

    /* Component Home Api*/        
    .container-home-api .home-api-infomation h2 {
        margin-left: 10%;
        font-size: 40px;
    }
            
    .container-home-api .home-api .home-api-infomation button{
        width: 80%;
        margin-bottom: 5rem;
        padding: 5% 10% 5% 8%;
    }
        
    .link-api{
        margin: 0 15%;
    }
    

    /* Component Footer */
    footer{
        margin: 1px auto 0 auto;
        padding: 80px 0;
        background-position: 50%;
    }

    .subscribe-footer {
        width: 100%;
        margin: 0 auto;
        padding: 0 0 3% 5%;    
    }

    .subscribe-footer .text {
        width: 21rem;
        margin: 0 2rem 0 0;
    }

    .subscribe-footer button{
        width: 65%;
        margin: 10% 10% 10% 0;
        padding: 18px 5px;
    }

    .subscribe-footer ul {
        margin-left: 1rem;
    }

    .subscribe-footer ul li {
        margin-bottom: 20px;
    }

    .table-api, .table-api th, .table-api tr, .table-api td {
        padding: 1rem; 
        font-size: 0.8rem;
    }

    /*Component ApiCodes*/
    .choose-language {
        font-size: 0.8em;
    }

    /*Contact*/
    .contact {
        width: 35rem;
        margin: 10rem auto;
        padding: 3rem;
    }

    .contact h1 {
        font-size: 1.8rem;
    }

    .contact form input,
    .contact form select,
    .contact form textarea {
        width: 30rem;
        height: 3rem;
        padding: 0.3rem;
    }

    .contact form textarea {
        height: 8rem;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

    /* Component Header */
    header::after {
        top: 60px;
        padding: 0 25px 0 8px;
        width: 100%;
    }
    
    header::before { top: 70px }

    /* Component Navbar */
    .navbar{ display: flex }
    
    /* Component Dropdown */
    .dropdown { display: none }

    /* Component HomeTop */
    .home-top {
        display: flex;
        flex-direction: row;
        justify-content:space-between;
        align-items:center;
        margin: 2rem auto 0 auto;
    }

    .page-title{
        width: 20rem;
        margin: 0;
        font-weight: 700;
        font-size: 2rem;
        line-height: 35px;
        text-align: left;
    }

    .home-top-information{
        margin: 0 1rem 0 8rem;
        align-self: flex-start;
        padding-right: 0;
    }

    .lead {
        font-size: 20px;
        text-align: left;
    }

    .home-top-cell{
        width: auto;
        padding: 0;
    }

    .download-container{ display: none}

    .download-container-992px {
        display: flex;
        align-items: flex-start;
    }

    .download-container-992px > div {
        padding-right: 1rem;
        align-items: flex-start;
    }

    .account{
        display: block;
    }

    /* Component Wave */
    .canvas-layout { display: block }

    /* Component HomeApi */
    .container-home-api .home-api{ padding: 0 5% }

    .api {
        padding-right: 15rem;
    }

    .container-home-api .home-api-infomation h2 {
        margin-left: 0;
        font-size: 40px;
    }
    
    .container-home-api .home-api .home-api-infomation button{
        margin: 0;
        width: 80%;
        padding: 5% 10% 5% 8%;
    }

    .link-api{ margin: 8% 38% 8% 5% }

    .container-home-api .home-api .home-api-img .img {
        width: auto;
        margin: 0;
        padding-bottom:6rem;
    }
    
    /*Component Carousel*/
    .container-carousel {
        position: relative;
        margin-top: -80px;
    }

    .carousel-box {
        width: 80%;
        margin-bottom: 20%;
    }
    .carousel-box-992px {
        padding: 45px 30px;
    }

    ul.react-multi-carousel-dot-list.custom-dot-list-style {
        display: none;
    }

    /* Component UsedBy */
    .used-by { 
        width: 100%; 
        height: 450px;
        padding: 80px 0 100px 0;
        background-color: #02060b;
    }

    .used-by-img > div { padding: 7rem 3.5rem }

    div.title-effect-usedby { margin-left: 30.8rem }

    /* Component Sign-Up */
    .subscribe-inner {
        width: 98%;
        margin: 0 auto;
    }

    .subscribe-inner {
        width: 90%;
        margin: 0 auto;
        padding: 0 0 3% 3%;
        flex-direction: row;
        justify-content:left;
    }

    .subscribe-inner h4{ font-size: 1.5rem }
    
    .subscribe-box form { margin: 50px 0 0 80px }
    
    .subscribe-box form input { padding: 0 10% }

    .subscribe-inner-text{
        align-self: flex-end;
        padding-bottom: 5%;
    }

    /*Component Footer*/
    footer{
        margin: 1px auto 0 auto;
        padding: 80px 0;
        background-position: 50%;
    }

    .subscribe-footer {
        width: 100%;
        margin: 0 auto;
        padding: 0 0 3% 5%;    
    }

    .subscribe-footer .text {
        width: 21rem;
        margin: 0 2rem 0 0;
    }

    .subscribe-footer button{
        width: 65%;
        margin: 10% 10% 10% 0;
        padding: 18px 5px;
    }

    .subscribe-footer ul { margin-left: 4rem }

    .subscribe-footer ul li { margin-bottom: 20px }   
    
    /*Component ApiCodes*/
    .choose-language {
        font-size: 1rem;
    }

    /* Views API */

    .link-api-collection {
        width: 17rem;
        margin-top: 1rem;
    }

    /* Views API's */
    .api {
        margin: 5rem 6rem;
        font-size: 1rem;
    }

    .api h1 {
        margin-top: 2rem;
        font-size: 2rem;
    }

    .table-api, .table-api th, .table-api tr, .table-api td {
        padding: 0.5rem; 
        font-size: 0.8rem;
    }

    .size-picture{ 
        max-width:40rem;
        max-height:40rem;
        width: auto;
        height: auto;
    }

    /*Component WithdrawForm */

    .line {
        width: 12%;
        margin-top: 0;
    }

    .withdrawform form input{
        margin-bottom: 10px;
        padding: 0.5rem 10rem;
    }
    
    .withdrawform form input.account-number{
        width: 20rem;
        padding: 0.5rem 1rem;
    }
    
    .withdrawform form input.digit-code{
        width: 10rem;
        padding: 0.5rem 0.3rem;
    }

    .withdrawform form label {
        font-size: 1rem;
    }

    /*Contact*/
    .contact {
        width: 35rem;
        margin: 5rem auto;
        padding: 5rem 0;
    }

    .contact h1 {
        font-size: 1.8rem;
    }
    
    .contact form input,
    .contact form select,
    .contact form textarea {
        width: 30rem;
        height: 3rem;
        padding: 0.3rem;
        margin: 10px 0 1px 0;
    }

    .contact form textarea {
        height: 8rem;
    }
    
    .contact form button {
        margin-top: 0.3rem;
    }

    .error-div {
        height: 30px;
        padding: 0;
    }

}


/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1024px) { 
    /*Component HomeTop*/
    .home-top-information{
        margin: 0 1rem 0 8rem;
        align-self: flex-start;
        padding-right: 1rem;
    }

    .home-top-cell{
        width: auto;
        margin-right: 12rem;
        padding: 0;
    }

    /*Component Wave */
    .canvas-layout { display: block }

    /*Component usedBy*/
    div .title-effect-usedby {
        margin-left: 47.5%;
    }

    /*Component Sign-Up*/
    .subscribe-inner {
        width: 90%;
        margin: 0 auto;
    }

    /*Component ApiCodes*/
    .choose-language {
        width: 100%;
        font-size: 0.7rem;
    }
}
